import React from "react";
import { Dashboard, Loading, Background, HistoryNodeTable } from "../../components";
import { useParams } from "react-router-dom";
import { UnitFull } from "../../util/interfaces";
import { useApi } from "../../hooks";

const HistoryPage = (): React.ReactElement => {
    const { id } = useParams();
    const [entries, setEntries] = React.useState<UnitFull[] | null>(null);
    const [res, { getHistory }] = useApi();

    React.useEffect(() => {
        setEntries(null);
        getHistory(id);
    }, [id]); // eslint-disable-line

    React.useEffect(() => {
        if (res) {
            setEntries(res.historical);
            setEntries((entries) =>
                (entries as UnitFull[]).sort(
                    (a, b) => Date.parse(a.archivization_date as string) - Date.parse(b.archivization_date as string)
                )
            );
            setEntries((entries) => [res.current, ...(entries as UnitFull[])]);
        }
    }, [res]); // eslint-disable-line

    return (
        <Dashboard title="Historia wpisów" scrollable>
            <Background>{entries ? <HistoryNodeTable units={entries} /> : <Loading />}</Background>
        </Dashboard>
    );
};

export default HistoryPage;
