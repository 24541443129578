import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Page500 = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography variant="h4" sx={{ marginBottom: 4 }}>
                Przepraszamy, wystąpił błąd serwera. Spróbuj ponownie później.
            </Typography>
            <Link to="/search">Powrót na stronę główną</Link>
        </Box>
    );
};

export default Page500;
