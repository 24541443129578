import React from "react";
import { useState } from "react";
import { Dashboard } from "../../components";
import { Grid, Typography, TextField, Collapse, IconButton, Button } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { Background } from "../../components";
import { UnitTable, Loading } from "../../components";
import { useApi } from "../../hooks";
import { UnitShort } from "../../util/interfaces";

const SearchPage = (): React.ReactElement => {
    const [openFilters, setOpenFilters] = useState(false);
    const [units, setUnits] = useState<UnitShort[] | null>(null);
    const [waiting, setWaiting] = useState(false);
    const [res, { searchUnits }] = useApi();

    const handleOpenFilters = () => setOpenFilters(!openFilters);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!waiting) {
            const values = new FormData(event.currentTarget);
            setUnits(null);
            searchUnits({
                unit_name: values.get("unit_name") || null,
                address: values.get("address") || null,
                website: values.get("website") || null,
                phone: values.get("phone") || null,
                email: values.get("email") || null,
                organizational_units: values.get("organizational_units") || null,
                research_areas: values.get("research_areas") || null,
                people_responsible: values.get("people_responsible") || null,
                infrastructure: values.get("infrastructure") || null,
                funding_sources: values.get("funding_sources") || null,
                completed_researches: values.get("completed_researches") || null,
                ongoing_researches: values.get("ongoing_researches") || null,
                offer_summary: values.get("offer_summary") || null,
                forms_of_cooperation: values.get("forms_of_cooperation") || null,
                partners: values.get("partners") || null,
                other: values.get("other") || null,
                classification: values.get("classification") || null,
                possible_usages: values.get("possible_usages") || null,
                offer_links: values.get("offer_links") || null,
            });
            setWaiting(true);
        }
    };

    React.useEffect(() => {
        searchUnits({
            unit_name: null,
            address: null,
            website: null,
            phone: null,
            email: null,
            organizational_units: null,
            research_areas: null,
            people_responsible: null,
            infrastructure: null,
            funding_sources: null,
            completed_researches: null,
            ongoing_researches: null,
            offer_summary: null,
            forms_of_cooperation: null,
            partners: null,
            other: null,
            classification: null,
            possible_usages: null,
            offer_links: null,
        });
    }, []); // eslint-disable-line

    React.useEffect(() => {
        if (res) {
            setUnits(res);
        }
    }, [res]); // eslint-disable-line

    return (
        <Dashboard title="Wyszukiwarka" scrollable>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Background>
                        <Grid container direction="column" spacing={2}>
                            <Grid item container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h6">Filtry</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleOpenFilters}>
                                        {openFilters ? <ArrowDropUp /> : <ArrowDropDown />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Collapse in={openFilters} sx={{ marginTop: 2 }}>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    spacing={2}
                                    component="form"
                                    onSubmit={handleSubmit}
                                >
                                    <Grid item>
                                        <TextField fullWidth label="Jednostka" name="unit_name" variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth label="Adres" name="address" variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth label="Strona www" name="website" variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth label="Telefon/fax" name="phone" variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth label="Adres e-mail" name="email" variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Nazwy komórek organizacyjnych podmiotów"
                                            name="organizational_units"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Obszary badawcze"
                                            name="research_areas"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Osoby odpowiedzialne za badania"
                                            name="people_responsible"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Infrastruktura naukowo-badawcza"
                                            name="infrastructure"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Źródła finansowania działalności badawczo-rozwojowej"
                                            name="funding_sources"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Zrealizowane projekty badawcze"
                                            name="completed_researches"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Realizowane projekty badawcze"
                                            name="ongoing_researches"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Charakterystyka oferowanych usług"
                                            name="offer_summary"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Charakterystyka form współpracy z otoczeniem zewnętrznym"
                                            name="forms_of_cooperation"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth label="Partnerzy" name="partners" variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Inne(Ekspertyzy, wdrożenia, patenty)"
                                            name="other"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Klasyfikacja publiczne, prywatne"
                                            name="classification"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Link do ofert technologicznych dla przedsiębiorców"
                                            name="offer_links"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item alignSelf="center">
                                        <Button variant="contained" type="submit">
                                            szukaj
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Background>
                </Grid>
                <Grid item md={7} xs={12}>
                    <Background>{units ? <UnitTable units={units} /> : <Loading />}</Background>
                </Grid>
            </Grid>
        </Dashboard>
    );
};

export default SearchPage;
