import React from "react";
import jwtDecode from "jwt-decode";
import { AuthContext } from "../../util/context";
import { useNavigate, useLocation } from "react-router-dom";

interface User {
    login: string;
    user_type: number;
}

const AuthHandler = ({ children }: React.PropsWithChildren<any>) => {
    const token = localStorage.getItem("jwt");
    const [jwt, setJwt] = React.useState<string | null>(token);
    const [user, setUser] = React.useState<User | null>(token ? jwtDecode(token) : null);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    React.useEffect(() => {
        if (jwt) {
            const data = jwtDecode<any>(jwt);
            if (data?.exp > Date.now() / 1000) {
                setUser(data as User);
                localStorage.setItem("jwt", jwt);
            } else {
                setJwt(null);
                if (pathname.startsWith("/admin") || pathname.startsWith("/moderator") || pathname.endsWith("/edit"))
                    navigate("/login");
            }
        } else {
            setUser(null);
            localStorage.removeItem("jwt");
        }
    }, [jwt]); // eslint-disable-line

    return (
        <AuthContext.Provider value={{ jwt, user, setJwt: setJwt as (jwt: string | null) => void }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthHandler;
