import React from "react";
import {
    Modal,
    Paper,
    Typography,
    TextField,
    Button,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
} from "@mui/material";
import { ModalProps } from "../props";
import { style } from "../style";
import { User, UnitShort } from "../../../util/interfaces";
import DeleteUserModal from "./DeleteUserModal";
import { useLoad } from "../../../pages/Admin/AdminPage";
import { useApi, useError } from "../../../hooks";

interface EditUserModalProps extends ModalProps {
    user: User;
    units: UnitShort[];
}

const EditUserModal = (props: EditUserModalProps): React.ReactElement => {
    const [openDelete, setOpenDelete] = React.useState(false);
    // when using objects for mui select references must match to get selected,
    // so we must filter selected units from all the units
    const [chosenUnits, setChosenUnits] = React.useState<UnitShort[]>(
        props.units.filter((unit) => props.user.permissions.some(({ id }) => unit.id === id))
    );
    const { statusCode, setStatusCode } = useError();
    const load = useLoad();
    const [res, { editUser, deleteUser }] = useApi();
    const [values, setValues] = React.useState({ login: "", password: "", password2: "" });
    const [waiting, setWaiting] = React.useState(false);

    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const handleDelete = () => {
        if (!waiting) {
            setStatusCode(200);
            deleteUser(props.user.id);
            setWaiting(true);
        }
    };

    const handleSubmit = () => {
        if (!waiting) {
            setStatusCode(200);
        }
        if (!waiting && values.login !== "" && values.password === values.password2 && chosenUnits.length > 0) {
            editUser(
                props.user.id,
                values.login,
                values.password || null,
                chosenUnits.map((unit) => unit.id)
            );
            setWaiting(true);
        }
    };

    const handleChange = (e: SelectChangeEvent<typeof chosenUnits>) => {
        setChosenUnits(e.target.value as UnitShort[]);
    };

    React.useEffect(() => {
        setValues({ login: props.user.login, password: "", password2: "" });
        setStatusCode(200);
    }, [props.open]); // eslint-disable-line

    React.useEffect(() => {
        if (res) {
            props.onClose();
            load();
        }
        setWaiting(false);
    }, [res]); // eslint-disable-line

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper sx={style}>
                <Grid container direction="column" spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Edycja użytkownika</Typography>
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            required
                            label="login"
                            variant="outlined"
                            fullWidth
                            value={values.login}
                            onChange={(e) => setValues({ ...values, login: e.target.value })}
                            error={values.login === ""}
                        />
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            label="hasło"
                            variant="outlined"
                            fullWidth
                            helperText="Pozostaw puste, jeśli hasło nie ulega zmianie"
                            value={values.password}
                            onChange={(e) => setValues({ ...values, password: e.target.value })}
                        />
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            label="powtórz hasło"
                            variant="outlined"
                            fullWidth
                            value={values.password2}
                            onChange={(e) => setValues({ ...values, password2: e.target.value })}
                            error={values.password !== values.password2}
                        />
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <FormControl required sx={{ width: "100%" }}>
                            <InputLabel>jednostki</InputLabel>
                            <Select
                                required
                                multiple
                                value={chosenUnits}
                                onChange={handleChange}
                                renderValue={(selected) => selected.map((el) => el.name).join(", ")}
                                label="jednostki *"
                                fullWidth
                            >
                                {props.units.map((unit, i) => (
                                    // @ts-ignore
                                    <MenuItem key={i} value={unit}>
                                        {unit.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {statusCode === 400 && (
                        <Grid item>
                            <Typography variant="body1" textAlign="center" sx={{ color: "red" }}>
                                Użytkownik już istnieje
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Button variant="contained" color="error" onClick={handleOpenDelete}>
                            usuń
                        </Button>
                    </Grid>
                    <Grid item container spacing={1} justifyContent="space-around">
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>
                                akceptuj
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="warning" onClick={props.onClose}>
                                anuluj
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <DeleteUserModal open={openDelete} onClose={handleCloseDelete} handleDelete={handleDelete} />
            </Paper>
        </Modal>
    );
};

export default EditUserModal;
