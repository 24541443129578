import React from "react";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { Background, UnitTable, Loading } from "../../../../components";
import { useUnits } from "../../AdminPage";
import { NewUnitModal } from "../../../../components/modals";

const UnitsSubpage = (): React.ReactElement => {
    const units = useUnits();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Background>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Jednostki</Typography>
                </Grid>
                <Grid item>
                    {units && (
                        <IconButton onClick={handleOpen}>
                            <AddIcon sx={{ fontSize: 32 }} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            {units ? <UnitTable admin units={units} /> : <Loading />}
            <NewUnitModal open={open} onClose={handleClose} />
        </Background>
    );
};

export default UnitsSubpage;
