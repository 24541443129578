import React from "react";
import { useState } from "react";
import { Dashboard, Background, Loading } from "../../components";
import { Typography, Grid, Box } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { UnitFull } from "../../util/interfaces";
import { useApi } from "../../hooks";

const DetailsPage = (): React.ReactElement => {
    const { id } = useParams();
    const [data, setData] = useState<UnitFull | null>(null);
    const [editable, setEditable] = useState(false);
    const [res, { getEntry }] = useApi();

    React.useEffect(() => {
        setData(null);
        getEntry(id);
    }, [id]); // eslint-disable-line

    React.useEffect(() => {
        if (res) {
            setData(res.entry);
            setEditable(res.has_edit_permissions);
        }
    }, [res]);

    return (
        <Dashboard title="Dane jednostki" scrollable>
            <Background>
                {data ? (
                    <Box
                        sx={{
                            "& > h5": { marginTop: 2 },
                        }}
                    >
                        <Typography variant="h5">Nazwa</Typography>
                        <Typography variant="body1">{data.unit_name || "brak danych"}</Typography>

                        <Typography variant="h5">Adres</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.address || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Strona www</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.website || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Telefon/fax</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.phone || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Adres e-mail</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.email || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Nazwy komórek organizacyjnych podmiotów</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.organizational_units || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Obszary badawcze</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.research_areas || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Osoby odpowiedzialne za badania</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.people_responsible || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Infrastruktura naukowo-badawcza</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.infrastructure || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Źródła finansowania działalności badawczo-rozwojowej</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.funding_sources || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Zrealizowane projekty badawcze</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.completed_researches || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Realizowane projekty badawcze</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.ongoing_researches || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Charakterystyka oferowanych usług</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.offer_summary || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Charakterystyka form współpracy z otoczeniem zewnętrznym</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.forms_of_cooperation || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Partnerzy</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.partners || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Inne(Ekspertyzy, wdrożenia, patenty)</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.other || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Klasyfikacja publiczne, prywatne</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.classification || "brak danych"}
                        </Typography>

                        <Typography variant="h5">
                            Branże, w jakich usługi świadczone przez badane podmioty znajdą zastosowanie
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {data.possible_usages || "brak danych"}
                        </Typography>

                        <Typography variant="h5">Link do ofert technologicznych dla przedsiębiorców</Typography>
                        <Grid container direction="column">
                            {data.offer_links ? (
                                data.offer_links.split("\n").map((link, i) => (
                                    <Grid item key={i}>
                                        <a href={link}>{link}</a>
                                    </Grid>
                                ))
                            ) : (
                                <Typography variant="body1">brak danych</Typography>
                            )}
                        </Grid>

                        <Grid container direction="column" sx={{ marginTop: 5 }}>
                            {editable && (
                                <Grid item>
                                    <Link to="./edit">Edytuj wpis</Link>
                                </Grid>
                            )}
                            <Grid item>
                                <Link to="./history">Historia wpisów</Link>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Loading />
                )}
                <Link to="/search">Powrót do wyszukiwarki</Link>
            </Background>
        </Dashboard>
    );
};

export default DetailsPage;
