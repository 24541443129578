import React from "react";

export type AuthContextType = {
    jwt: string | null;
    user: { login: string; user_type: number } | null;
    setJwt: (jwt: string | null) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
    jwt: null,
    user: null,
    setJwt: (jwt: string | null) => {},
});
