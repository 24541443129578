import React from "react";
import { Typography, Link } from "@mui/material";

const Copyright: React.FC<any> = (props: any): JSX.Element => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" mt="auto" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="http://smartsoftsolutions.pl/">
                Smart Soft Solutions
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
};

export default Copyright;
