import React from "react";
import { useState } from "react";
import { Dashboard, Background, Loading } from "../../components";
import { Typography, TextField, Button, Box } from "@mui/material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { UnitFull } from "../../util/interfaces";
import { useApi, useError } from "../../hooks";

const EditPage = (): React.ReactElement => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState<UnitFull | null>(null);
    const { setStatusCode } = useError();
    const [res, { getEntry }] = useApi();
    const [resEdit, { editEntry }] = useApi();
    const [waiting, setWaiting] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!waiting) {
            const values = new FormData(event.currentTarget);
            editEntry({
                unit_id: id,
                entry_id: (data as UnitFull).id,
                address: values.get("address"),
                website: values.get("website"),
                phone: values.get("phone"),
                email: values.get("email"),
                organizational_units: values.get("organizational_units"),
                research_areas: values.get("research_areas"),
                people_responsible: values.get("people_responsible"),
                infrastructure: values.get("infrastructure"),
                funding_sources: values.get("funding_sources"),
                completed_researches: values.get("completed_researches"),
                ongoing_researches: values.get("ongoing_researches"),
                offer_summary: values.get("offer_summary"),
                forms_of_cooperation: values.get("forms_of_cooperation"),
                partners: values.get("partners"),
                other: values.get("other"),
                classification: values.get("classification"),
                possible_usages: values.get("possible_usages"),
                offer_links: values.get("offer_links"),
            });
            setWaiting(true);
        }
    };

    React.useEffect(() => {
        setData(null);
        getEntry(id);
    }, [id]); // eslint-disable-line

    React.useEffect(() => {
        if (res) {
            if (!res.has_edit_permissions) setStatusCode(404);
            else {
                setData(res.entry);
            }
        }
    }, [res]); // eslint-disable-line

    React.useEffect(() => {
        setWaiting(false);
        if (resEdit) navigate(`/unit/${id}`);
    }, [resEdit]); // eslint-disable-line

    return (
        <Dashboard title="Dane jednostki" scrollable>
            <Background>
                {data ? (
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            "& > h5": { marginTop: 2 },
                        }}
                    >
                        <Typography variant="h5">Nazwa</Typography>
                        <Typography variant="body1">{data.unit_name}</Typography>

                        <Typography variant="h5">Adres</Typography>
                        <TextField multiline fullWidth variant="outlined" name="address" defaultValue={data.address} />

                        <Typography variant="h5">Strona www</Typography>
                        <TextField multiline fullWidth variant="outlined" name="website" defaultValue={data.website} />

                        <Typography variant="h5">Telefon/fax</Typography>
                        <TextField multiline fullWidth variant="outlined" name="phone" defaultValue={data.phone} />

                        <Typography variant="h5">Adres e-mail</Typography>
                        <TextField multiline fullWidth variant="outlined" name="email" defaultValue={data.email} />

                        <Typography variant="h5">Nazwy komórek organizacyjnych podmiotów</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="organizational_units"
                            defaultValue={data.organizational_units}
                        />

                        <Typography variant="h5">Obszary badawcze</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="research_areas"
                            defaultValue={data.research_areas}
                        />

                        <Typography variant="h5">Osoby odpowiedzialne za badania</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="people_responsible"
                            defaultValue={data.people_responsible}
                        />

                        <Typography variant="h5">Infrastruktura naukowo-badawcza</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="infrastructure"
                            defaultValue={data.infrastructure}
                        />

                        <Typography variant="h5">Źródła finansowania działalności badawczo-rozwojowej</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="funding_sources"
                            defaultValue={data.funding_sources}
                        />

                        <Typography variant="h5">Zrealizowane projekty badawcze</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="completed_researches"
                            defaultValue={data.completed_researches}
                        />

                        <Typography variant="h5">Realizowane projekty badawcze</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="ongoing_researches"
                            defaultValue={data.ongoing_researches}
                        />

                        <Typography variant="h5">Charakterystyka oferowanych usług</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="offer_summary"
                            defaultValue={data.offer_summary}
                        />

                        <Typography variant="h5">Charakterystyka form współpracy z otoczeniem zewnętrznym</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="forms_of_cooperation"
                            defaultValue={data.forms_of_cooperation}
                        />

                        <Typography variant="h5">Partnerzy</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="partners"
                            defaultValue={data.partners}
                        />

                        <Typography variant="h5">Inne(Ekspertyzy, wdrożenia, patenty)</Typography>
                        <TextField multiline fullWidth variant="outlined" name="other" defaultValue={data.other} />

                        <Typography variant="h5">Klasyfikacja publiczne, prywatne</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="classification"
                            defaultValue={data.classification}
                        />

                        <Typography variant="h5">
                            Branże, w jakich usługi świadczone przez badane podmioty znajdą zastosowanie
                        </Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="possible_usages"
                            defaultValue={data.possible_usages}
                        />

                        <Typography variant="h5">Link do ofert technologicznych dla przedsiębiorców</Typography>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="offer_links"
                            defaultValue={data.offer_links}
                            helperText="Każdy kolejny link należy podawać od nowej linii"
                        />

                        <Button
                            variant="contained"
                            color="warning"
                            sx={{ marginTop: 2, marginBottom: 2 }}
                            type="submit"
                        >
                            akceptuj zmiany
                        </Button>
                    </Box>
                ) : (
                    <Loading />
                )}
                <Link to="/search">Powrót do wyszukiwarki</Link>
            </Background>
        </Dashboard>
    );
};

export default EditPage;
