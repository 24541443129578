import React from "react";
import { Dashboard } from "../../components";
import { Outlet } from "react-router-dom";
import { User, UnitShort } from "../../util/interfaces";
import { useApi } from "../../hooks";

type ContextType = { users: User[] | null; units: UnitShort[] | null; load: () => void };

const Context = React.createContext<ContextType>({ users: null, units: null, load: () => {} });

const AdminPage = (): React.ReactElement => {
    const [users, setUsers] = React.useState<User[] | null>(null);
    const [units, setUnits] = React.useState<UnitShort[] | null>(null);
    const [res_users, { getUsers }] = useApi();
    const [res_units, { getUnits }] = useApi();

    const load = () => {
        setUsers(null);
        setUnits(null);
        getUsers();
        getUnits();
    };

    // eslint-disable-next-line
    React.useEffect(load, []);

    React.useEffect(() => {
        setUsers(res_users);
    }, [res_users]);

    React.useEffect(() => {
        setUnits(res_units);
    }, [res_units]);

    return (
        <Dashboard title="Panel administratora" scrollable>
            <Context.Provider value={{ users, units, load }}>
                <Outlet />
            </Context.Provider>
        </Dashboard>
    );
};

export const useUsers = () => {
    const { users } = React.useContext(Context);
    return users;
};

export const useUnits = () => {
    const { units } = React.useContext(Context);
    return units;
};

export const useLoad = () => {
    const { load } = React.useContext(Context);
    return load;
};

export default AdminPage;
