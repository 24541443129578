import React from "react";
import {
    Modal,
    Paper,
    Typography,
    TextField,
    Button,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
} from "@mui/material";
import { ModalProps } from "../props";
import { style } from "../style";
import { UnitShort } from "../../../util/interfaces";
import { useLoad } from "../../../pages/Admin/AdminPage";
import { useApi, useError } from "../../../hooks";

interface NewUserModalProps extends ModalProps {
    units: UnitShort[];
}

const NewUserModal = (props: NewUserModalProps): React.ReactElement => {
    const [chosenUnits, setChosenUnits] = React.useState<UnitShort[]>([]);
    const [waiting, setWaiting] = React.useState(false);
    const [values, setValues] = React.useState({ login: "", password: "", password2: "" });
    const [res, { addUser }] = useApi();
    const load = useLoad();
    const { statusCode, setStatusCode } = useError();

    const handleChange = (e: SelectChangeEvent<typeof chosenUnits>) => {
        setChosenUnits(e.target.value as UnitShort[]);
    };

    const handleSubmit = () => {
        if (!waiting) {
            setStatusCode(200);
        }
        if (
            !waiting &&
            values.login !== "" &&
            values.password !== "" &&
            values.password === values.password2 &&
            chosenUnits.length > 0
        ) {
            addUser(
                values.login,
                values.password,
                chosenUnits.map((unit) => unit.id)
            );
            setWaiting(true);
        }
    };

    React.useEffect(() => {
        setValues({ login: "", password: "", password2: "" });
        setChosenUnits([]);
        setStatusCode(200);
    }, [props.open]); // eslint-disable-line

    React.useEffect(() => {
        if (res) {
            props.onClose();
            load();
        }
        setWaiting(false);
    }, [res]); // eslint-disable-line

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper sx={style}>
                <Grid container direction="column" spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Dodawanie użytkownika</Typography>
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            required
                            label="login"
                            value={values.login}
                            onChange={(e) => setValues({ ...values, login: e.target.value })}
                            variant="outlined"
                            fullWidth
                            error={values.login === ""}
                        />
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            required
                            label="hasło"
                            value={values.password}
                            onChange={(e) => setValues({ ...values, password: e.target.value })}
                            variant="outlined"
                            fullWidth
                            error={values.password === ""}
                            type="password"
                        />
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            required
                            label="powtórz hasło"
                            value={values.password2}
                            onChange={(e) => setValues({ ...values, password2: e.target.value })}
                            variant="outlined"
                            fullWidth
                            error={values.password !== values.password2}
                            type="password"
                        />
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <FormControl required sx={{ width: "100%" }}>
                            <InputLabel>jednostki</InputLabel>
                            <Select
                                required
                                multiple
                                value={chosenUnits}
                                onChange={handleChange}
                                renderValue={(selected) => selected.map((el) => el.name).join(", ")}
                                label="jednostki *"
                                fullWidth
                                error={chosenUnits.length === 0}
                            >
                                {props.units.map((unit, i) => (
                                    // @ts-ignore
                                    <MenuItem key={i} value={unit}>
                                        {unit.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {statusCode === 400 && (
                        <Grid item>
                            <Typography variant="body1" textAlign="center" sx={{ color: "red" }}>
                                Użytkownik już istnieje
                            </Typography>
                        </Grid>
                    )}
                    <Grid item container spacing={1} justifyContent="space-around">
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>
                                akceptuj
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="warning" onClick={props.onClose}>
                                anuluj
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default NewUserModal;
