import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks";
import { Page404 } from "../../pages";

interface PrivateOutletProps {
    admin?: boolean;
    moderator?: boolean;
}

const PrivateOutlet = ({ admin, moderator }: PrivateOutletProps): React.ReactElement => {
    const { user } = useAuth();

    if (moderator && !admin) {
        return user?.user_type === 1 ? <Outlet /> : <Page404 />;
    }
    if (admin && !moderator) {
        return user?.user_type === 2 ? <Outlet /> : <Page404 />;
    }
    return user ? <Outlet /> : <Page404 />;
};

export default PrivateOutlet;
