import React from "react";
import { Modal, Paper, Typography, Grid } from "@mui/material";
import { ModalProps } from "../props";
import { UnitFull } from "../../../util/interfaces";

interface NodeModalProps extends ModalProps {
    unit: UnitFull;
}

const NodeModal = (props: NodeModalProps): React.ReactElement => {
    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    wordWrap: "break-word",
                    overflowY: "auto",
                    borderRadius: 5,
                    p: 3,
                    whiteSpace: "pre-line",
                }}
            >
                <Typography variant="h5">Nazwa</Typography>
                <Typography variant="body1">{props.unit.unit_name}</Typography>

                <Typography variant="h5">Adres</Typography>
                <Typography variant="body1">{props.unit.address || "brak danych"}</Typography>

                <Typography variant="h5">Strona www</Typography>
                <Typography variant="body1">{props.unit.website || "brak danych"}</Typography>

                <Typography variant="h5">Telefon/fax</Typography>
                <Typography variant="body1">{props.unit.phone || "brak danych"}</Typography>

                <Typography variant="h5">Adres e-mail</Typography>
                <Typography variant="body1">{props.unit.email || "brak danych"}</Typography>

                <Typography variant="h5">Nazwy komórek organizacyjnych podmiotów</Typography>
                <Typography variant="body1">{props.unit.organizational_units || "brak danych"}</Typography>

                <Typography variant="h5">Obszary badawcze</Typography>
                <Typography variant="body1">{props.unit.research_areas || "brak danych"}</Typography>

                <Typography variant="h5">Osoby odpowiedzialne za badania</Typography>
                <Typography variant="body1">{props.unit.people_responsible || "brak danych"}</Typography>

                <Typography variant="h5">Infrastruktura naukowo-badawcza</Typography>
                <Typography variant="body1">{props.unit.infrastructure || "brak danych"}</Typography>

                <Typography variant="h5">Źródła finansowania działalności badawczo-rozwojowej</Typography>
                <Typography variant="body1">{props.unit.funding_sources || "brak danych"}</Typography>

                <Typography variant="h5">Zrealizowane projekty badawcze</Typography>
                <Typography variant="body1">{props.unit.completed_researches || "brak danych"}</Typography>

                <Typography variant="h5">Realizowane projekty badawcze</Typography>
                <Typography variant="body1">{props.unit.ongoing_researches || "brak danych"}</Typography>

                <Typography variant="h5">Charakterystyka oferowanych usług</Typography>
                <Typography variant="body1">{props.unit.offer_summary || "brak danych"}</Typography>

                <Typography variant="h5">Charakterystyka form współpracy z otoczeniem zewnętrznym</Typography>
                <Typography variant="body1">{props.unit.forms_of_cooperation || "brak danych"}</Typography>

                <Typography variant="h5">Partnerzy</Typography>
                <Typography variant="body1">{props.unit.partners || "brak danych"}</Typography>

                <Typography variant="h5">Inne(Ekspertyzy, wdrożenia, patenty)</Typography>
                <Typography variant="body1">{props.unit.other || "brak danych"}</Typography>

                <Typography variant="h5">Klasyfikacja publiczne, prywatne</Typography>
                <Typography variant="body1">{props.unit.classification || "brak danych"}</Typography>

                <Typography variant="h5">
                    Branże, w jakich usługi świadczone przez badane podmioty znajdą zastosowanie
                </Typography>
                <Typography variant="body1">{props.unit.possible_usages || "brak danych"}</Typography>

                <Typography variant="h5">Link do ofert technologicznych dla przedsiębiorców</Typography>
                <Grid container direction="column">
                    {props.unit.offer_links?.split(/[, \n]/).map((link, i) => (
                        <Grid item key={i}>
                            <a href={link}>{link}</a>
                        </Grid>
                    )) || <Typography variant="body1">brak danych</Typography>}
                </Grid>
            </Paper>
        </Modal>
    );
};

export default NodeModal;
