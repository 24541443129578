import React from "react";
import { Dashboard } from "../../components";
import { UnitTable } from "../../components";
import { Typography } from "@mui/material";
import { Background, Loading } from "../../components";
import { UnitShort } from "../../util/interfaces";
import { useApi } from "../../hooks";

const ModeratorPage = (): React.ReactElement => {
    const [units, setUnits] = React.useState<UnitShort[] | null>(null);
    const [res, { getUnits }] = useApi();

    const load = () => {
        setUnits(null);
        getUnits();
    };

    // eslint-disable-next-line
    React.useEffect(load, []);

    React.useEffect(() => {
        if (res) {
            setUnits(res);
        }
    }, [res]);

    return (
        <Dashboard title="Panel moderatora" scrollable>
            <Background>
                <Typography variant="h4">Lista dostępnych jednostek</Typography>
                {units ? <UnitTable units={units} /> : <Loading />}
            </Background>
        </Dashboard>
    );
};

export default ModeratorPage;
