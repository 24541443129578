import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { EditUserModal } from "../modals";
import { User, UnitShort } from "../../util/interfaces";

interface UserTableElementProps {
    key: number;
    idx: number;
    user: User;
    units: UnitShort[];
}

const UserTableElement = (props: UserTableElementProps): React.ReactElement => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <TableRow sx={{ "&:hover": { cursor: "pointer" } }} onClick={handleOpen}>
                <TableCell component="th" scope="row">
                    {props.idx}
                </TableCell>
                <TableCell>{props.user.login}</TableCell>
            </TableRow>
            <EditUserModal open={open} onClose={handleClose} user={props.user} units={props.units} />
        </>
    );
};

export default UserTableElement;
