import React from "react";
import { useLocation } from "react-router-dom";
import { Page404, Page500 } from "../../pages";
import { ErrorContext } from "../../util/context";

const ErrorHandler = ({ children }: React.PropsWithChildren<any>) => {
    const location = useLocation();
    const [statusCode, setStatusCode] = React.useState<number>(200);

    React.useEffect(() => {
        setStatusCode(200);
    }, [location]);

    const render = () => {
        if (statusCode === 500 || (statusCode === 400 && location.pathname !== "/admin/users")) return <Page500 />;
        if (statusCode > 400 && statusCode !== 403 && location.pathname !== "/login") return <Page404 />;
        return children;
    };

    return (
        <ErrorContext.Provider value={{ statusCode, setStatusCode: setStatusCode as () => void }}>
            {render()}
        </ErrorContext.Provider>
    );
};

export default ErrorHandler;
