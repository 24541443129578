import React from "react";
import { Modal, Paper, Typography, Button, Grid } from "@mui/material";
import { ModalProps } from "../props";
import { style } from "../style";

interface DeleteUserModalProps extends ModalProps {
    handleDelete: () => void;
}

const DeleteUserModal = (props: DeleteUserModalProps): React.ReactElement => {
    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper sx={style}>
                <Grid container direction="column" spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Usuwanie użytkownika</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" textAlign="center">
                            Ta operacja usunie nieodwracalnie użytkownika. Czy kontynuować?
                        </Typography>
                    </Grid>
                    <Grid item container spacing={1} justifyContent="space-around">
                        <Grid item>
                            <Button variant="contained" color="warning" onClick={props.handleDelete}>
                                akceptuj
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={props.onClose}>
                                anuluj
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default DeleteUserModal;
