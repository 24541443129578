import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
    LoginPage,
    AdminPage,
    UsersSubpage,
    UnitsSubpage,
    ModeratorPage,
    SearchPage,
    DetailsPage,
    EditPage,
    HistoryPage,
    Page404,
} from "./pages";
import { PrivateOutlet, ErrorHandler, AuthHandler } from "./components";
import { ThemeProvider } from "@mui/material";
import { plPL } from "@mui/material/locale";
import { createTheme } from "@mui/material";

const theme = createTheme(plPL);

const App: React.FC = (): JSX.Element => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthHandler>
                    <ErrorHandler>
                        <Routes>
                            <Route path="/" element={<Navigate to="/search" />} />
                            <Route path="/search" element={<SearchPage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/unit/:id">
                                <Route path="" element={<DetailsPage />} />
                                <Route path="history" element={<HistoryPage />} />
                                <Route path="edit" element={<PrivateOutlet />}>
                                    <Route path="" element={<EditPage />} />
                                </Route>
                            </Route>
                            <Route path="/admin" element={<PrivateOutlet admin />}>
                                <Route path="" element={<AdminPage />}>
                                    <Route path="" element={<Navigate to="users" />} />
                                    <Route path="users" element={<UsersSubpage />} />
                                    <Route path="units" element={<UnitsSubpage />} />
                                </Route>
                            </Route>
                            <Route path="/moderator" element={<PrivateOutlet moderator />}>
                                <Route path="" element={<ModeratorPage />} />
                            </Route>
                            <Route path="*" element={<Page404 />} />
                        </Routes>
                    </ErrorHandler>
                </AuthHandler>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
