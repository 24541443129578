import React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Copyright } from "../../components";
import { Paper } from "@mui/material";
import { useAuth } from "../../hooks";
import { ListItem, List, ListItemText, ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LoginIcon from "@mui/icons-material/Login";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

interface DashboardProps {
    title: string;
    children?: any;
    scrollable?: boolean;
}

const Dashboard = (props: DashboardProps) => {
    const [open, setOpen] = React.useState(false);
    const { user, setJwt } = useAuth();
    const navigate = useNavigate();
    const toggleDrawer = () => {
        console.log(open);
        setOpen(!open);
    };

    const drawer = (
        <>
            <Toolbar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer} sx={{ display: { sm: "none" } }}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <Box sx={{ height: "100%", width: "100%" }}>
                <List>
                    {user?.user_type === 2 && (
                        <>
                            <ListItem button onClick={() => navigate("/admin/users")}>
                                <ListItemIcon>
                                    <AccountBoxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Użytkownicy" />
                            </ListItem>
                            <ListItem button onClick={() => navigate("/admin/units")}>
                                <ListItemIcon>
                                    <AccountBalanceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Jednostki" />
                            </ListItem>
                        </>
                    )}
                    {user?.user_type === 1 && (
                        <ListItem button onClick={() => navigate("/moderator")}>
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Twoje jednostki" />
                        </ListItem>
                    )}
                </List>
            </Box>
            <Divider />
            <List>
                <ListItem button onClick={() => navigate("/search")}>
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Wyszukiwarka" />
                </ListItem>
                {user ? (
                    <ListItem
                        button
                        onClick={() => {
                            setJwt(null);
                            navigate("/", { replace: true });
                        }}
                    >
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Wyloguj" />
                    </ListItem>
                ) : (
                    <ListItem button onClick={() => navigate("/login")}>
                        <ListItemIcon>
                            <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary="Zaloguj" />
                    </ListItem>
                )}
            </List>
        </>
    );

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    zIndex: open ? 0 : 1,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: "36px",
                            display: { sm: "none" },
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="left"
                open={open}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { sm: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                open
                sx={{ display: { xs: "none", sm: "block", height: "100vh", position: "fixed" } }}
            >
                {drawer}
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
                    display: "flex",
                    minHeight: "100vh",
                    overflow: "auto",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <Toolbar />
                {props.scrollable ? (
                    <Box display="flex" pt={4} px={2} sx={{ wordBreak: "break-word", ml: { sm: `${drawerWidth}px` } }}>
                        {props.children}
                    </Box>
                ) : (
                    <Box display="flex" height="85%" justifyContent="center" pt={4} px={2}>
                        <Paper
                            elevation={3}
                            sx={{
                                width: "100%",
                                borderRadius: 5,
                                padding: 3,
                                maxHeight: "100%",
                                overflow: "auto",
                                wordBreak: "break-word",
                            }}
                        >
                            {props.children}
                        </Paper>
                    </Box>
                )}
                <Copyright p={3} />
            </Box>
        </Box>
    );
};

export default Dashboard;
