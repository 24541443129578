import React from "react";
import { Modal, Paper, Typography, Button, Grid } from "@mui/material";
import { ModalProps } from "../props";
import { style } from "../style";

interface DeleteUnitModalProps extends ModalProps {
    handleDelete: () => void;
}

const DeleteUnitModal = (props: DeleteUnitModalProps): React.ReactElement => {
    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper sx={style}>
                <Grid container direction="column" spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Usuwanie jednostki</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" textAlign="center">
                            Jednostka, jej wpis oraz historia wpisów zostaną nieodwracalnie usunięte. Czy kontynuować?
                        </Typography>
                    </Grid>
                    <Grid item container spacing={1} justifyContent="space-around">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    props.onClose();
                                    props.handleDelete();
                                }}
                            >
                                akceptuj
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="warning" onClick={props.onClose}>
                                anuluj
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default DeleteUnitModal;
