import React from "react";
import { useState } from "react";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { Background, UserTable, Loading } from "../../../../components";
import { NewUserModal } from "../../../../components/modals";
import { useUsers, useUnits } from "../../AdminPage";

const UsersSubpage = (): React.ReactElement => {
    const users = useUsers();
    const units = useUnits();
    const [openNew, setOpenNew] = useState(false);

    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);

    return (
        <Background>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Użytkownicy</Typography>
                </Grid>
                <Grid item>
                    {users && (
                        <IconButton onClick={handleOpenNew}>
                            <AddIcon sx={{ fontSize: 32 }} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            {users && units ? <UserTable users={users} units={units} /> : <Loading />}
            {units ? <NewUserModal open={openNew} onClose={handleCloseNew} units={units} /> : <div />}
        </Background>
    );
};

export default UsersSubpage;
