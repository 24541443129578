import React from "react";
import { Box, Typography } from "@mui/material";

const Loading = (): React.ReactElement => {
    return (
        <Box sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
                Ładowanie...
            </Typography>
        </Box>
    );
};

export default Loading;
