import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../../components";
import { Link } from "react-router-dom";
import { useApi, useError, useAuth } from "../../hooks";
import { useNavigate } from "react-router-dom";

const SignIn: React.FC = (): JSX.Element => {
    const { statusCode, setStatusCode } = useError();
    const [res, { login }] = useApi();
    const { user, setJwt } = useAuth();
    const [isWaiting, setIsWaiting] = React.useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!isWaiting) {
            setStatusCode(200);
            setIsWaiting(true);
            login(data.get("username") as string, data.get("password") as string);
        }
    };

    React.useEffect(() => {
        if (user?.user_type === 1) navigate("/moderator");
        if (user?.user_type === 2) navigate("/admin");
    }, [user, navigate]);

    React.useEffect(() => {
        setIsWaiting(false);
        if (res) {
            setJwt(res.jwt);
            navigate("/search");
        }
    }, [res, navigate, setJwt]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Logowanie
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Nazwa użytkownika"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Hasło"
                        type="password"
                        autoComplete="current-password"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        zaloguj
                    </Button>
                    {statusCode !== 200 && (
                        <Typography variant="body1" sx={{ color: "red" }}>
                            Błędne dane!
                        </Typography>
                    )}
                </Box>
            </Box>
            <Link to="/search">Powrót do wyszukiwarki</Link>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
};

export default SignIn;
