import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { EditUnitModal } from "../modals";
import { UnitShort } from "../../util/interfaces";
import { useNavigate } from "react-router-dom";

interface UnitTableElementProps {
    key: number;
    idx: number;
    unit: UnitShort;
    admin?: boolean;
}

const UnitTableElement = (props: UnitTableElementProps): React.ReactElement => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <TableRow
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                    if (props.admin) handleOpen();
                    else navigate(`/unit/${props.unit.id}`);
                }}
            >
                <TableCell component="th" scope="row">
                    {props.idx}
                </TableCell>
                <TableCell sx={{ whiteSpace: "pre-line" }}>{props.unit.name}</TableCell>
            </TableRow>
            <EditUnitModal open={open} onClose={handleClose} unit={props.unit} />
        </>
    );
};

export default UnitTableElement;
