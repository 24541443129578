import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography variant="h2" sx={{ marginBottom: 4 }}>
                Przepraszamy, żądana strona nie istnieje.
            </Typography>
            <Link to="/search">Powrót na stronę główną</Link>
        </Box>
    );
};

export default Page404;
