import React from "react";
import { Modal, Paper, Typography, TextField, Button, Grid } from "@mui/material";
import { ModalProps } from "../props";
import { style } from "../style";
import { UnitShort } from "../../../util/interfaces";
import { useNavigate } from "react-router-dom";
import { useLoad } from "../../../pages/Admin/AdminPage";
import { useApi } from "../../../hooks";
import DeleteUnitModal from "./DeleteUnitModal";

interface EditUnitModalProps extends ModalProps {
    unit: UnitShort;
}

const EditUnitModal = (props: EditUnitModalProps): React.ReactElement => {
    const navigate = useNavigate();
    const [waiting, setWaiting] = React.useState(false);
    const [res, { editUnit, deleteUnit }] = useApi();
    const [name, setName] = React.useState("");
    const load = useLoad();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        if (!waiting) {
            editUnit(props.unit.id, name);
            setWaiting(true);
        }
    };

    const handleDelete = () => {
        if (!waiting) {
            deleteUnit(props.unit.id);
            setWaiting(true);
        }
    };

    React.useEffect(() => setName(props.unit.name), [props.open, props.unit]);

    React.useEffect(() => {
        if (res) {
            setWaiting(false);
            props.onClose();
            load();
        }
    }, [res]); // eslint-disable-line

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper sx={style}>
                <Grid container direction="column" spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Edycja jednostki</Typography>
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            required
                            label="nazwa"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => navigate(`/unit/${props.unit.id}/edit`)}>
                            przejdź do wpisu
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="error" onClick={handleOpen}>
                            usuń jednostkę
                        </Button>
                    </Grid>
                    <Grid item container spacing={1} justifyContent="space-around">
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>
                                akceptuj
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="warning" onClick={props.onClose}>
                                anuluj
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <DeleteUnitModal open={open} onClose={handleClose} handleDelete={handleDelete} />
            </Paper>
        </Modal>
    );
};

export default EditUnitModal;
