import React from "react";
import { Modal, Paper, Typography, TextField, Button, Grid } from "@mui/material";
import { ModalProps } from "../props";
import { style } from "../style";
import { useApi } from "../../../hooks";
import { useLoad } from "../../../pages/Admin/AdminPage";

const NewUnitModal = (props: ModalProps): React.ReactElement => {
    const [res, { addUnit }] = useApi();
    const [waiting, setWaiting] = React.useState(false);
    const [name, setName] = React.useState("");
    const load = useLoad();

    const handleSubmit = () => {
        if (!waiting && name !== "") {
            addUnit(name);
            setWaiting(true);
        }
    };

    React.useEffect(() => {
        if (res) {
            setWaiting(false);
            load();
            props.onClose();
        }
    }, [res]); // eslint-disable-line

    React.useEffect(() => setName(""), [props.open]);

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper sx={style}>
                <Grid container direction="column" spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Dodawanie jednostki</Typography>
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            required
                            label="nazwa"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            error={name === ""}
                            multiline
                        />
                    </Grid>
                    <Grid item container spacing={1} justifyContent="space-around">
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>
                                akceptuj
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="warning" onClick={props.onClose}>
                                anuluj
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default NewUnitModal;
