import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { NodeModal } from "../modals";
import { UnitFull } from "../../util/interfaces";

interface HistoryNodeProps {
    unit: UnitFull;
    idx: number;
}

const HistoryNode = ({ unit, idx }: HistoryNodeProps) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <TableRow sx={{ "&:hover": { cursor: "pointer" } }} onClick={handleOpen}>
                <TableCell component="th" scope="row">
                    {idx}
                </TableCell>
                <TableCell>
                    {unit.archivization_date
                        ? new Date(Date.parse(unit.archivization_date)).toLocaleString("pl-PL", {
                              dateStyle: "short",
                              timeStyle: "short",
                          })
                        : "Aktualny"}
                </TableCell>
            </TableRow>
            <NodeModal open={open} onClose={handleClose} unit={unit} />
        </>
    );
};

export default HistoryNode;
