import React from "react";
import api from "../util/api/api";
import { useError, useAuth } from ".";

const useApi = () => {
    const { setStatusCode } = useError();
    const { jwt, setJwt } = useAuth();
    const [res, setRes] = React.useState<any>(null);

    const auth = {
        headers: {
            Authorization: jwt || "",
        },
    };

    const query = (resp: Promise<any>) =>
        resp
            .then(({ data }) => {
                if (data.refresh) {
                    setJwt(data.refresh);
                }
                setRes(data.payload || data);
            })
            .catch((err) => {
                // this triggers effects on res even if request fails
                setRes(false);
                setRes(null);
                setStatusCode(err?.response?.status || 500);
            });

    const login = (login: string, password: string) => query(api.post("login", { login, password }));

    const getUsers = () => query(api.get("/users", auth));

    const getUnits = () => query(api.get("/units", auth));

    const searchUnits = (data: any) => query(api.post("/search", data, auth));

    const addUnit = (name: string) => query(api.post("/new_unit", { name }, auth));

    const editUnit = (id: number, name: string) => query(api.put(`/unit/${id}`, { name }, auth));

    const deleteUnit = (id: number) => query(api.delete(`/unit/${id}`, auth));

    const addUser = (login: string, password: string, permissions: number[]) =>
        query(api.post("/register", { login, password, permissions, user_type: 1 }, auth));

    const editUser = (id: number, login: string | null, password: string | null, permissions: number[]) =>
        query(api.put(`/user/${id}`, { login, password, permissions, user_type: null }, auth));

    const deleteUser = (id: number) => query(api.delete(`/user/${id}`, auth));

    const getEntry = (id: number) => query(api.get(`/unit/entry/${id}`, auth));

    const editEntry = (data: any) => query(api.put("/unit/entry", data, auth));

    const getHistory = (id: number) => query(api.get(`/entry/${id}/historical`, auth));

    return [
        res,
        {
            login,
            getUsers,
            getUnits,
            searchUnits,
            addUnit,
            editUnit,
            deleteUnit,
            addUser,
            editUser,
            deleteUser,
            getEntry,
            editEntry,
            getHistory,
        },
    ];
};

export default useApi;
